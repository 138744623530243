import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className="title font-white"><b>Comming Soon</b></h1>
    <p className="desc font-white">Our website is currently undergoing scheduled maintenance.
      We Should be back shortly. Thank you for your patience.</p>
  </Layout>
)

export default IndexPage
